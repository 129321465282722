/*
 *	Ntriga.Agency jQuery plugin
 */

(function ($) {

	/*
	 *	Maak alle elementen even hoog als het hoogste. Vergelijkt elementen met dezelfde Y met elkaar.
	 * 	!! Oproepen na window.load zodat de afbeeldingen geladen zijn !!
	 *
	 *	$('.selector').equalizeHeight();
	 */

	$.fn.equalizeHeight = function () {
		
		// Groeperen per Y positie
		var currentY = 0;

		this.all_elements = new Array();
		var self = this;

		$.each(this, function(index, element) {	
			var same_row = true;
			
			if ($(element).offset().top != currentY) {
				currentY = $(element).offset().top;
				same_row = false;
			}

			if (!same_row) {
				self.all_elements.push(new Array());
			}
			self.all_elements[self.all_elements.length-1].push(element);
		});

		// Hoogtes instellen

		$(this).css('min-height', 'auto');

		$(self.all_elements).each( function(index, element) {
			var maxHeight = 0;

			$(this).each(function(index, element) {	
				if ($(element).outerHeight() > maxHeight) {
					maxHeight = Math.ceil($(element).outerHeight());
				}
			});

			$(this).each(function(index, element) {
				$(element).css('min-height', maxHeight+'px');
			});
			
		});
	};

} (jQuery));