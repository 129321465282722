// Set site info
var siteinfo = document.getElementsByName("siteinfo")[0];
var site_id = siteinfo.getAttribute("data-siteid");
var lang = siteinfo.getAttribute("data-sitelang");

$(document).ready(function ()
{
	// Lightbox - UNCOMMENT ME BIJ KOPPELEN

	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});

	$('.open-nav').click(function(){
		$('body').addClass('is-nav-open');
	});
	$('.close-nav').click(function(){
		$('body').removeClass('is-nav-open');
	});

	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}

	$(".scroll-to").click(function(e) {
		e.preventDefault();
		var element = $(this).attr('href')
	    $('html, body').animate({
	        scrollTop: $(element).offset().top
	    }, 1000);
	});



	/*
	 *	SLIDERS
	 */

	$('#slider-work').slick({
		asNavFor: '#slider-work-thumbs',
		slidesToShow: 1,
  		slidesToScroll: 1,
  		prevArrow: '<button class="slick-prev"><i class="icon-angle-left"></i></button>',
  		nextArrow: '<button class="slick-next"><i class="icon-angle-right"></i></button>'
	});

	$('#slider-work-thumbs').slick({
		asNavFor: '#slider-work',
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: true,
		focusOnSelect: true,
		prevArrow: '<button class="slick-prev"><i class="icon-angle-left"></i></button>',
  		nextArrow: '<button class="slick-next"><i class="icon-angle-right"></i></button>'
	});

});

$(window).on('load', function(){

	/*
	 *	LAYOUT STUFF
	 */

	$('.work__title').equalizeHeight();

	function timerTick () {
		if (didResize) {
			$('.work__title').equalizeHeight();
		}
		didResize = false;
	}

	setInterval( timerTick, 30 );
	didResize = true;
	timerTick();

	$(window).resize( function() {
		didResize = true;
	});



	/*
	 *	SCROLL REVEAL
	 */

	window.sr = ScrollReveal({ duration: 500, delay: 10, scale: 1, origin: 'top'});
	sr.reveal('.sr', 50);
	sr.reveal('.sr-left', {origin: 'left'}, 50);
});